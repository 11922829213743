<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <form @submit.prevent="adding">
      <div
        class="alert alert-danger"
        v-if="has_error"
      >
        {{ error_msg }}
      </div>
      <div class="form-group">
        <animal-detail :show-detail="true" />
      </div>
      <fieldset>
        <legend>
          Couverture
          <div>
            <a
              href="javascript:void(0)"
              @click.prevent="incrementMedoc"
              class="btn btn-outline-primary btn-sm"
            >
              <i class="icofont icofont-plus" />
            </a>
            <a
              href="javascript:void(0)"
              @click.prevent="decrementMedoc"
              class="btn btn-outline-third btn-sm"
            >
              <i class="icofont icofont-close" />
            </a>
          </div>
        </legend>
        <poly-vaccin
          v-for="i in index"
          :key="i"
          :index="i"
          style="margin: 5px 0px; "
        />
      </fieldset>
      
      
      <div class="form-group">
        <label for="add-info">Information supplementaire</label>
        <textarea
          class="form-control"
          name="add-info"
          id="add-info"
          rows="5"
          v-model="info"
        />
      </div>
      <div class="form-group text-right">
        <button
          type="submit"
          class="btn btn-third"
        >
          Ajouter
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import navbar from '../../../components/navbar.vue'
import animalDetail from '../../../components/identification/animal/detailInform.vue'
import PolyVaccin from '../../../components/sanitaire/vaccination/polyVaccin.vue'
import { ADD_VACCINATION } from '../../../graphql/sanitaire'
export default {
    components: {navbar, animalDetail, PolyVaccin},
    data(){
        return {
          navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Vaccination',
                route: 'vaccination'
              },
              {libelle: 'Ajout'}
            ],
            pageIcon: 'la-id-card',
            pageTitle: 'Ajout vaccination',
            pageDescription: 'Ajouter une nouvelle vaccination sur un animal',
            maladie: null,
            filterdVaccins: [],
            vaccin: null,
            animal: null,
            info: null,  
            has_error: false,
            error_msg: null,
          
        }
    },
    beforeMount(){
      this.initMedoc()
    },
    watch: {
        maladie(){
            if(this.maladie !== null && this.selectedAnimal !== null){
                let animal = this.animaux.find(item=> item.uid === this.selectedAnimal)
                let espece = this.especes.find(item => item.uid === animal.espece)
                this.filterdVaccins = [...this.vaccins].filter(item => item.maladie === this.maladie && item.espece === espece.uid)
            }else this.filterdVaccins = []
        }
    },
    methods: {
        ...mapMutations({
              initMedoc: 'sanitaire/INIT_INDEX_MEDICAMENT',
              decrementMedoc: 'sanitaire/DECREMENT_INDEX_MEDICAMENT',
              incrementMedoc: 'sanitaire/INCREMENT_INDEX_MEDICAMENT'
        }),
        initForm(){
            this.maladie = null
            this.vaccin = null
            this.animal = null
            this.info = null
            this.filterdVaccins = []
        },
        adding(){
            let data = {
                animal: this.selectedAnimal,
                info: this.info
            }
            this.$apollo.mutate({
                mutation: ADD_VACCINATION,
                variables: {
                    "vaccination": {
                        ...data
                    },
                    "poly":[
                      ...this.vaccinations
                    ] 
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Vaccination ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.$router.push('vaccination')
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
          index: 'sanitaire/indexMedicaments',
          vaccinations: 'sanitaire/traitementMedocs',
          selectedAnimal: 'identification/selectedAnimal',
          vaccins: 'sanitaire/vaccins',
        }),
        
    } 
}
</script>

<style lang="scss" scoped>
legend{
            position: relative;
            div{
                position: absolute;
                left: 200px;
                top: 0px;
                a{
                    margin-left: 5px;
                }
            }
        }
</style>